import React from "react";
import styled from "styled-components";
import "./App.css";

const Container = styled.div`
  display: flex;
  height: 100vh;
  font-family: "Arial", sans-serif;
  padding-left: 6rem;
  padding-right: 6rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
    height: auto;
  }
`;

const LeftPanel = styled.div`
  width: 35%;
  background-color: transparent;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const NameHeader = styled.div`
  font-size: 2.1rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const RightPanel = styled.div`
  width: 65%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    padding: 1.5rem 0;
  }
`;

const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 1rem;
`;

const Section = styled.div`
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h2`
  margin-bottom: 0.5rem;
  color: black;
`;

const SectionContent = styled.p`
  color: #333;

  & p {
    text-align: justify;
    margin-bottom: 1rem;
  }
`;

const Motto = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
`;

const Footer = styled.div`
  font-size: 0.9rem;
  color: #555;
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
`;

const Portfolio = () => {
  return (
    <Container>
      <LeftPanel>
        <NameHeader>MEDHI <span style={{fontWeight: "bold"}}>RACHICO</span></NameHeader>
      </LeftPanel>
      <RightPanel>
        <div>
          <Motto>
            I help people make better decisions using data, for a greener
            future-proof economy.
          </Motto>
          <SectionList>
            <Section>
              <SectionContent>
                <p>
                  Founder of Metrikflow, The all-in-one ESG Platform for
                  Industrial Companies. Metrikflow is a SaaS solution that helps
                  companies measure, track and reduce their carbon footprint and
                  comply with overwhelming sustainability regulations. We
                  Leverage technology to support our partners in achieving
                  climate goals and lead the sustainability transition with
                  confidence.
                </p>
                <p>
                  4+ years of experience building and optimizing software
                  applications with a focus on backend technologies, API
                  development, software architecture, data engineering and
                  machine learning. Adept at working with cross-functional
                  teams, driving end-to-end project lifecycles, and delivering
                  innovative, data-driven solutions in dynamic and fast-paced
                  environments, implementing DevOps best practices for seamless
                  deployment and system reliability.
                </p>
                <p>
                  I like people who are passionate about what they do and who
                  are focused on solving problems rather than reacting to them.{" "}
                </p>
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle>ORGNIZATIONS</SectionTitle>
              <SectionContent>
                <p>
                  Co-Founder & CTO,{" "}
                  <span style={{ fontStyle: "italic" }}>Metrikflow</span>
                  <br />
                  <span style={{ color: "#B8B8B8" }}>2022 → Present</span>
                </p>
                <p>
                  Software Engineer,{" "}
                  <span style={{ fontStyle: "italic" }}>Odoo</span>
                  <br />
                  <span style={{ color: "#B8B8B8" }}>2022 → Present</span>
                </p>
                <p>
                  Machine Learning Engineer,{" "}
                  <span style={{ fontStyle: "italic" }}>Forenamics</span>
                  <br />
                  <span style={{ color: "#B8B8B8" }}>2022</span>
                </p>
                <p>
                  Data Engineer,{" "}
                  <span style={{ fontStyle: "italic" }}>
                    Wall Street Football
                  </span>
                  <br />
                  <span style={{ color: "#B8B8B8" }}>2021</span>
                </p>
                <p>
                  Software Engineer,{" "}
                  <span style={{ fontStyle: "italic" }}>Independant</span>
                  <br />
                  <span style={{ color: "#B8B8B8" }}>2019–2021</span>
                </p>
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle>COLLABORATIONS & PROJECTS</SectionTitle>
              <SectionContent>
                I'm always open to working with driven individuals or teams
                building towards meaningful impact. Let's join forces on
                innovation, sustainability, and community-centric technology.
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle>IN THEIR OWN WORDS</SectionTitle>
              <SectionContent>
                <p style={{ fontStyle: "italic" }}>
                  "Mehdi had a huge impact on Wall Street Football. He supported
                  our Team of Data Scientists in several analytical tasks and at
                  the same time he owned quite a few projects independently.
                  Mehdi is very strong in term of technical skills, but at the
                  same time he understands the business component very well and
                  like to see the full picture. Mehdi has also very good
                  communication ability, he is a smart and kind person and he is
                  a great team-player. I am sure that he is going to have a very
                  big impact in every project/company/team that he joins."
                </p>
                <span style={{ color: "#B8B8B8" }}>
                  {" "}
                  — Giovanni Bertoli - Co-founder & CEO at Wall Street Football
                  | Ex-Googler
                </span>
                <p style={{ fontStyle: "italic", marginTop: "2rem" }}>
                  "Mehdi has proved himself as an exceptional analyst and data
                  scientist. He helped Wall Street Football solve a range of
                  technical issues relating to the infrastructure and base of
                  our models. He has applied techniques in machine learning,
                  data science software development and analysis as a means to
                  achieving the business tasks set upon him. Apart from that it
                  was really easy and straightforward to communicate with Mehdi
                  as he was always showing a positive and open attitude during
                  difficult tasks, involving technologies such as R, Python,
                  MongoDB and Git. I would recommend Mehdi as a valuable member
                  of any analytics team as he is able to effectively leverage
                  his technical skills and communication style to get the job
                  done."
                </p>
                <span style={{ color: "#B8B8B8" }}>
                  {" "}
                  — Yevgen Levin - Head of Data Engineering at WSF Odds
                </span>
                <p style={{ fontStyle: "italic", marginTop: "2rem" }}>
                  "I have been teaching Mehdi this year advanced techniques of
                  deep learning techniques for images and graphs and he has
                  shown assiduity, discipline, good learning skills and big
                  interest in deep learing study field."
                </p>
                <span style={{ color: "#B8B8B8" }}>
                  {" "}
                  — Racha Friji - AI/ Quantum AI Researcher | PhD in AI
                  |Techwomen Fellow 2022 | Ex-Googler
                </span>
              </SectionContent>
            </Section>
          </SectionList>
        </div>
        <Footer>
          <div>
            mehdi@metrikflow.com |{" "}
            <a
              href="https://www.linkedin.com/in/mehdi-rachico/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
          </div>
          <div style={{color: "#B8B8B8"}}>&copy; 2025 Mehdi Rachico</div>
        </Footer>
      </RightPanel>
    </Container>
  );
};

export default Portfolio;
